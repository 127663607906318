<template>
  <b-card>
    <b-row>
      <!-- User Info: Left col -->
      <b-col
        cols="21"
        xl="6"
        class="d-flex justify-content-between flex-column"
      >
        <!-- User Avatar & Action Buttons -->
        <div class="d-flex justify-content-start">
          <b-avatar
            :src="avatar"
            text=""
            variant="light-success"
            size="185px"
            rounded
          />
          <div class="d-flex flex-column ml-1">
            <div class="mb-1">
              <h2 class="mb-1 text-primary text-uppercase">
                <b>{{ userData.username }}</b>
              </h2>
            </div>
            <div class="d-flex flex-wrap">
              <b-button
                :to="{ name: 'apps-users-edit', params: { id: userData.id } }"
                variant="primary"
              >
                {{ $t('actions.edit') }}
              </b-button>
            </div>
          </div>
        </div>
      </b-col>
      <!-- Right Col: Table -->
      <b-col
        cols="12"
        xl="6"
      >
        <table class="mt-2 mt-xl-0 w-100">
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="UserIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">{{ $t('user.username') }}</span>
            </th>
            <td class="pb-50">
              {{ userData.username }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="CheckIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">{{ $t('user.status') }}</span>
            </th>
            <td class="pb-50 text-capitalize">
              <b-badge
                pill
                :variant="`light-${resolveUserStatusVariant(userData.status)}`"
                class="text-capitalize"
              >
                {{ resolveUserStatusMessage(userData.status) }}
              </b-badge>
            </td>
          </tr>

          <tr>
            <th class="pb-50">
              <feather-icon
                icon="BookOpenIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">{{ $t('user.firtName') }}</span>
            </th>
            <td class="pb-50 text-capitalize">
              {{ userData.firt_name }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="BookOpenIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">{{ $t('user.middleName') }}</span>
            </th>
            <td class="pb-50 text-capitalize">
              {{ userData.middle_name }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="BookOpenIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">{{ $t('user.surname1') }}</span>
            </th>
            <td class="pb-50 text-capitalize">
              {{ userData. surname_1 }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="BookOpenIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">{{ $t('user.surname2') }}</span>
            </th>
            <td class="pb-50 text-capitalize">
              {{ userData.surname_2 }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="MailIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">{{ $t('user.email') }}</span>
            </th>
            <td class="pb-50">
              {{ userData.email }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="BriefcaseIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">{{ $t('user.jobTitle') }}</span>
            </th>
            <td class="pb-50 text-capitalize">
              {{ getTypeValue(userData, 'job_title') }}
            </td>
          </tr>
          <tr>
            <th>
              <feather-icon
                icon="HomeIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">{{ $t('user.homeAddress') }}</span>
            </th>
            <td>
              {{ userData.home_address }}
            </td>
          </tr>
          <tr>
            <th>
              <feather-icon
                icon="PhoneIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">{{ $t('user.workCellPhone') }}</span>
            </th>
            <td>
              {{ userData.work_cell_phone }}
            </td>
          </tr>
          <tr>
            <th>
              <feather-icon
                icon="PhoneIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">{{ $t('user.cellPhone') }}</span>
            </th>
            <td>
              {{ userData.cell_phone }}
            </td>
          </tr>

          <tr>
            <th>
              <feather-icon
                icon="BookOpenIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">{{ $t('user.medicalRecord') }}</span>
            </th>
            <td>
              {{ userData.medical_record }}
            </td>
          </tr>
          <tr>
            <th>
              <feather-icon
                icon="BookOpenIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">{{ $t('user.occupationalMedicalLicense') }}</span>
            </th>
            <td>
              {{ userData.occupational_medical_license }}
            </td>
          </tr>

          <tr>
            <th class="pb-50">
              <feather-icon
                icon="MapPinIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">{{ $t('user.department') }}</span>
            </th>
            <td class="pb-50 text-capitalize">
              {{ getTypeValue(userData, 'department') }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="MapPinIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">{{ $t('user.municipality') }}</span>
            </th>
            <td class="pb-50 text-capitalize">
              {{ getTypeValue(userData, 'municipality') }}
            </td>
          </tr>
        </table>
      </b-col>
    </b-row>
    <hr>
    <!-- Action Buttons -->
    <b-row>
      <b-col>
        <router-link :to="{name: 'apps-users-list'}">
          <b-button
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
          >
            {{ $t('actions.back') }}
          </b-button>
        </router-link>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BButton, BAvatar, BRow, BCol, BBadge,
} from 'bootstrap-vue'

import { avatarText } from '@core/utils/filter'

export default {
  components: {
    BCard, BButton, BRow, BCol, BAvatar, BBadge,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
    avatar: {
      type: String,
      required: true,
    },
  },
  setup() {
    return {
      avatarText,
      getTypeValue(user, type) {
        return user[type] ? user[type].value : ''
      },
      resolveUserStatusVariant(status) {
        if (status == 0) return 'warning'
        return 'success'
      },
      resolveUserStatusMessage(status) {
        if (status == 0) return this.$t('user.statusValue.inactive')
        return this.$t('user.statusValue.active')
      },
    }
  },
}
</script>

<style>

</style>
