<template>
  <div>
    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="userData === undefined"
    >
      <h4 class="alert-heading">
        Error fetching user data
      </h4>
    </b-alert>

    <template v-if="userData">
      <b-row>
        <b-col
          cols="12"
        >
        <user-info-card :user-data="userData" :avatar="avatar" />
        </b-col>
      </b-row>
    </template>

  </div>
</template>

<script>
import store from '@/store'
import router from '@/router'
import {
  BRow, BCol, BAlert, BLink,
} from 'bootstrap-vue'
import UserStoreModule from '../storeModule'
import UserInfoCard from './InfoCard.vue'

export default {
  components: {
    BRow,
    BCol,
    BAlert,
    BLink,
    // Local Components
    UserInfoCard,
  },
  data() {
    return {
      userData: undefined,
      avatar: '',
      USER_APP_STORE_MODULE_NAME: 'app-user'
    }
  },
  mounted() {
    // Register module
    if (!store.hasModule(this.USER_APP_STORE_MODULE_NAME)) store.registerModule(this.USER_APP_STORE_MODULE_NAME, UserStoreModule)

    this.fetchUser()
  },
  unmounted() {
    // UnRegister on leave
    if (store.hasModule(this.USER_APP_STORE_MODULE_NAME)) store.unregisterModule(this.USER_APP_STORE_MODULE_NAME)
  },
  methods: {
    fetchUser() {
      store.dispatch('app-user/fetchUser', { id: router.currentRoute.params.id })
      .then(response => { 
        this.userData = response.data 
        if (this.userData.avatar_id) {
          this.fetchImage(this.userData.avatar_id)
        }
      })
      .catch(error => {
        if (error.response.status === 404) {
          console.info('404', this.userData)
          userData = undefined
        }
      })
    },
    fetchImage(avatar_id) {
      store.dispatch('app-user/fetchImage', { id: avatar_id })
      .then(response => { 
        this.avatar = response.data.img 
      })
      .catch(error => {
        if (error.response.status === 404) {
          console.info('404', this.userData)
          userData = undefined
        }
      })
    },
  }
}
</script>

<style>

</style>
